import React from "react";
import ReactGA from "react-ga";

const openExternalLink = (e) => {
  // e.preventDefault();
  // this.props.openExternalLink(e.target.getAttribute("href"));
  // this.trackClick(
  //   e.target.getAttribute("data-trackcategory"),
  //   e.target.getAttribute("data-trackaction"),
  //   e.target.getAttribute("data-tracklabel"),
  //   e.target.getAttribute("data-trackvalue")
  // );
};

const trackClick = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
class BaseModule extends React.Component {
  openExternalLink = openExternalLink;
  trackClick = trackClick;
}

export default BaseModule;
export { openExternalLink, trackClick };
