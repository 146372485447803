import React, { useState, useEffect } from "react";
import "focus-visible";
import "./App.scss";
import Header from "./Header";
import Welcome from "./Welcome";
import Overview from "./Overview";
import About from "./About";
import Objectives from "./Objectives";
import HowToPlay from "./HowToPlay";
import ForTeachers from "./ForTeachers";
import Footer from "./Footer";
import MobileDetect from "mobile-detect";
import ReactGA from "react-ga";
import Modal from "react-modal";
import HeroVideo from "./HeroVideo";

Modal.setAppElement("#root");
ReactGA.initialize(process.env.REACT_APP_GID);
ReactGA.pageview(window.location.pathname + window.location.search);
const sections = [
  "welcome",
  "about",
  "overview",
  "objectives",
  "how-to-play",
  "for-teachers",
];
const pageScrollTracking = [0, 25, 50, 75, 100];
const tracked = [];
let navigationClicked = false;
let navigationTimeout;
let scrollTimeout;

const App = () => {
  const [animationOn, setAnimationOn] = useState(false);
  const [navigationSection, setNavigationSection] = useState("welcome");
  const [menuOpen, setMenuOpen] = useState(false);
  const [forceMobileMenu, setForceMobileMenu] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [externalURL, setExternalUrl] = useState("");

  let scrollWait = false;

  const checkScroll = () => {
    if (scrollWait) return;
    let scrollTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = scrollTop / (docHeight - winHeight);
    let scrollPercentRounded = Math.round(scrollPercent * 100);
    if (pageScrollTracking.length > 0) {
      for (let index = 0; index < pageScrollTracking.length; index++) {
        const depth = pageScrollTracking[index];
        if (scrollPercentRounded >= depth && tracked.indexOf(depth) === -1) {
          tracked.push(depth);
          ReactGA.event({
            category: "scroll",
            action: "percentscroll",
            label: depth,
          });
          break;
        }
      }
    }
    let headerOffset = window.innerWidth < 639 ? 61 : 0;
    let newSection = navigationClicked ? navigationSection : "";
    for (let index = 0; index < sections.length; index++) {
      const section = sections[index];
      const currentSection = document.querySelector(`#${section}`);
      if (currentSection) {
        const rect = document
          .querySelector(`#${section}`)
          .getBoundingClientRect();
        if (rect.top <= 50 + headerOffset && !navigationClicked)
          newSection = section;
      }
    }
    setNavigationSection(newSection);

    scrollWait = true;
    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout((event) => {
      scrollWait = false;
      checkScroll();
    }, 50);
  };
  const scrollToSection = (sectionID) => {
    navigationClicked = true;
    scrollWait = true;
    setNavigationSection(sectionID);
    if (navigationTimeout) clearTimeout(navigationTimeout);
    navigationTimeout = setTimeout((event) => {
      navigationClicked = false;
      scrollWait = false;
      checkScroll();
    }, 2000);

    setMenuOpen(false);
    setTimeout(() => {
      setNavigationSection(sectionID);
      document.getElementById(sectionID + "-focus").focus();
      let section = document.getElementById(sectionID);
      ReactGA.event({
        category: "navigation",
        action: "link click",
        label: sectionID,
      });
      let scrollTop = window.pageYOffset || section.scrollTop;
      let headerOffset = window.innerWidth < 639 ? 61 : 0;

      window.scrollTo({
        top: section.getBoundingClientRect().top + scrollTop - headerOffset,
        behavior: "smooth",
      });
    }, 100);
  };
  const openExternalLink = (url) => {
    setExternalUrl(url);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setTimeout(() => {
      setExternalUrl("");
      setModalIsOpen(false);
    }, 0);
  };
  useEffect(() => {
    determineAnimationStatus();
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    // document.addEventListener("keydown", escFunction);
    window.addEventListener("resize", determineAnimationStatus);
    return () => {
      // document.removeEventListener("keydown", escFunction);
      window.removeEventListener("resize", determineAnimationStatus);
      window.removeEventListener("scroll", checkScroll);
    };
  });

  const determineAnimationStatus = () => {
    const md = new MobileDetect(window.navigator.userAgent);

    if (
      window.innerWidth / window.outerWidth <= 0.5 ||
      window.innerWidth < 859 ||
      md.mobile()
    ) {
      setAnimationOn(false);
      setForceMobileMenu(true);
    } else {
      setForceMobileMenu(false);
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: "navigation",
      action: "expand",
      label: menuOpen ? "open" : "close",
    });

    document.body.style.overflow = menuOpen ? "hidden" : "";
  }, [menuOpen]);

  return (
    <div className="App">
      <div className="app-container">
        <Header
          animation={animationOn}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          forceMobileMenu={forceMobileMenu}
          currentSection={navigationSection}
          scrollToSection={scrollToSection}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Modal-Overlay"
          contentLabel="You're leaving ally fintropolis"
        >
          <h2>
            You're leaving <span className="grapefruit">ally fintropolis.</span>
          </h2>
          <p>
            Selecting Continue will take you to a website that Ally does not own
            or control. We are not responsible for the products, services, or
            information you may find or provide there. Also, you should read and
            understand how that site's privacy, level of security, and terms and
            conditions may impact you.
          </p>
          <p className="buttons">
            <a
              href={externalURL}
              target="_blank"
              rel="noreferrer"
              className="button"
              onClick={closeModal}
            >
              Continue
            </a>{" "}
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
          </p>
        </Modal>
        <main role="main" aria-hidden={menuOpen ? true : false}>
          <HeroVideo />
          <Welcome />
          <About />
          <Overview
            animation={animationOn}
            forceMobileMenu={forceMobileMenu}
            openExternalLink={openExternalLink}
          />
          <Objectives
            animation={animationOn}
            forceMobileMenu={forceMobileMenu}
          />
          <HowToPlay forceMobileMenu={forceMobileMenu} />
          <ForTeachers openExternalLink={openExternalLink} />
        </main>
        <Footer menuOpen={menuOpen} />
      </div>
    </div>
  );
};

export default App;
