import React from "react";
import "./StickyNav.scss";

const StickyNav = ({
  currentSection,
  menuOpen,
  forceMobileMenu,
  scrollToSection,
}) => {
  const navigateTo = (sectionID) => {
    scrollToSection(sectionID);
  };

  //129w 102h
  const nav = (
    <nav
      className={`sticky-nav${forceMobileMenu ? " forceMobileMenu" : ""}${
        menuOpen ? " show" : ""
      }`}
      role="navigation"
      aria-label="in page links"
      aria-hidden={menuOpen ? false : true}
    >
      <div className="mobile-nav-header"></div>

      <button
        className={`nav-button ${currentSection === "welcome" ? "active" : ""}`}
        onClick={() => navigateTo("welcome")}
        aria-label="Navigate to Welcome Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 36 30">
            <g>
              <path d="M3.7,11.7c-0.7,0-1.2,0-1.8,0c-1.3,0-1.9-0.9-1.3-2.1C1.8,7.4,3.1,5.2,4.3,3c0.4-0.7,0.9-1.4,1.2-2.1 C5.8,0.1,6.4,0,7.1,0C14.4,0,21.7,0,29,0c0.7,0,1.3,0.2,1.6,0.8c1.6,2.8,3.1,5.6,4.6,8.4c0.9,1.7,0.4,2.5-1.5,2.5 c-0.4,0-0.8,0-1.3,0c0,0.5,0,0.8,0,1.2c0,5.1,0,10.2,0,15.2c0,1.4-0.4,1.8-1.8,1.8c-8.4,0-16.8,0-25.1,0c-1.4,0-1.8-0.4-1.8-1.8 c0-5,0-10.1,0-15.1C3.7,12.6,3.7,12.2,3.7,11.7z M29.5,27.2c0-5.2,0-10.3,0-15.4c-7.7,0-15.3,0-23,0c0,5.2,0,10.3,0,15.4 c1.1,0,2,0,3,0c0-3.1,0-6.1,0-9.1c0-1,0.4-1.5,1.4-1.5c2.4,0,4.8,0,7.2,0c0.8,0,1.2,0.4,1.2,1.2c0,2.7,0,5.4,0,8.2 c0,0.4,0,0.8,0,1.3C22.8,27.2,26.1,27.2,29.5,27.2z M31.8,8.9c-0.2-0.5-0.4-0.9-0.6-1.2c-0.8-1.4-1.7-2.8-2.4-4.3 c-0.3-0.7-0.7-0.8-1.4-0.8c-5.9,0-11.9,0.1-17.8,0C8.3,2.5,7.4,2.7,6.8,4C6.1,5.7,5.1,7.2,4.2,8.9C13.5,8.9,22.6,8.9,31.8,8.9z M12.4,19.2c0,2.6,0,5.2,0,7.8c1.5,0,2.9,0,4.3,0c0-2.6,0-5.2,0-7.8C15.2,19.2,13.9,19.2,12.4,19.2z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">Welcome</div>
      </button>
      <button
        className={`nav-button ${currentSection === "about" ? "active" : ""}`}
        onClick={() => navigateTo("about")}
        aria-label="Navigate to About Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 25 32">
            <g>
              <path d="M0,16.7c0-4.4,0-8.9,0-13.3c0-0.2,0-0.5,0-0.7c0.1-1.2,0.4-1.4,1.6-1.4c4.9,0,9.9,0,14.8,0 c0.7,0,1.2,0.2,1.7,0.7c2,2,4,4.1,6.1,6.1C24.8,8.6,25,9.2,25,9.9c0,6.9,0,13.7,0,20.6c0,1.2-0.3,1.5-1.5,1.5c-7.3,0-14.7,0-22,0 C0.3,32,0,31.7,0,30.5c0-3,0-6.1,0-9.1C0,19.8,0,18.2,0,16.7z M22.7,29.9c0-6.5,0-13,0-19.5c-1.9,0-3.7,0-5.6,0 c-1,0-1.3-0.3-1.3-1.3c0-0.4,0-0.8,0-1.3c0-1.4,0-2.9,0-4.3c-4.5,0-9,0-13.4,0c0,8.8,0,17.6,0,26.4C9.1,29.9,15.9,29.9,22.7,29.9z M18.1,8.2c1,0,2,0,2.8,0C20,7.3,19,6.3,18.1,5.4C18.1,6.2,18.1,7.2,18.1,8.2z" />
              <path d="M14.3,16c1.5,0,3,0,4.6,0c0.8,0,1.1,0.4,1.1,1.1c0,0.6-0.4,1.1-1.1,1.1c-3.1,0-6.1,0-9.2,0 c-0.7,0-1.2-0.2-1.2-1s0.5-1.1,1.2-1.1C11.2,16,12.7,16,14.3,16z" />
              <path d="M14.1,22.5c-1.5,0-3,0-4.6,0c-0.7,0-1.1-0.3-1.1-1c0-0.8,0.4-1.1,1.2-1.1c3,0,6,0,9,0c0.7,0,1.3,0.2,1.4,1 c0.1,0.7-0.5,1.1-1.4,1.1C17.1,22.5,15.6,22.5,14.1,22.5z" />
              <path d="M5.2,21.4c0-0.7,0.4-1.1,1.1-1.1s1.2,0.5,1.1,1.2c0,0.7-0.4,1.1-1.1,1.1C5.6,22.5,5.2,22.1,5.2,21.4z" />
              <path d="M6.4,15.9c0.7,0,1.1,0.3,1.1,1.1c0,0.7-0.4,1-1.1,1c-0.8,0-1.2-0.3-1.1-1.1C5.2,16.2,5.5,15.9,6.4,15.9z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">About</div>
      </button>
      <button
        className={`nav-button ${
          currentSection === "overview" ? "active" : ""
        }`}
        onClick={() => navigateTo("overview")}
        aria-label="Navigate to Overview Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 31 33">
            <g>
              <path d="M18.7,18.7c0.6,0.5,1.1,1.1,1.7,1.6c0.1,0.1,0.4,0.3,0.5,0.3c1.1-0.1,1.8,0.5,2.4,1.2 c2.3,2.3,4.6,4.6,6.9,6.9c0.9,0.9,1,2.4,0.2,3.2c-0.9,0.9-2.4,1-3.4,0.1c-2.5-2.4-4.9-4.9-7.3-7.4c-0.5-0.5-0.5-1.3-0.9-1.9 c-0.3-0.6-0.8-1.1-1.1-1.7c-0.5-0.7-1-0.7-1.9-0.3c-3.6,1.8-7.3,1.8-10.8-0.3c-3.6-2.2-5.2-5.5-5.1-9.6C0,8.3,0.9,6,2.6,4.1 C5.1,1.2,8.3,0,12,0.4c4.8,0.5,8.7,4.1,9.5,9C22.1,12.9,21,15.9,18.7,18.7z M19.3,11.2c0.1-4.5-3.6-8.5-8.4-8.6 c-4.5,0-8.4,3.6-8.5,8.4c-0.1,4.8,3.9,8.7,8.5,8.7C15.4,19.7,19.5,15.7,19.3,11.2z" />
              <path d="M4.6,11.1c0-2.5,1.8-5,4.3-5.9c0.3-0.1,1,0.1,1.2,0.4s0.1,0.9,0,1.3c0,0.2-0.3,0.4-0.5,0.5 c-1.7,0.8-2.5,2.3-2.7,4c-0.1,0.8-0.4,1.3-1.2,1.3c-0.7,0-1.1-0.5-1.1-1.5C4.6,11.2,4.6,11.1,4.6,11.1z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">Overview</div>
      </button>
      <button
        className={`nav-button ${
          currentSection === "objectives" ? "active" : ""
        }`}
        onClick={() => navigateTo("objectives")}
        aria-label="Navigate to Objectives Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 150.15 198.3">
            <g>
              <path d="M33.23,198.3l-.91,0H0V167a20,20,0,0,1,40,0v31.14l-5.36.14ZM11,187.28H29V167a9,9,0,0,0-18,0Z" />
              <path d="M21.5,156a5.5,5.5,0,0,1-5.5-5.5V5.5a5.5,5.5,0,0,1,11,0v145A5.5,5.5,0,0,1,21.5,156Z" />
              <path d="M119.4,110a96.62,96.62,0,0,1-26.5-4,5.21,5.21,0,0,1-.74-.3C44.37,82.3,28.79,96.56,26.45,99.21a4.78,4.78,0,0,1-1,1.26,5.64,5.64,0,0,1-4,1.67,5.49,5.49,0,0,1-5.18-7.34c.58-5.62.28-40.59-.32-71.66a5.45,5.45,0,0,1,.81-3C17.52,19,34.94-8,96.61,22c8.89,2.74,33.13,7.27,44.32-3A5.5,5.5,0,0,1,150.15,23v73.6a5.48,5.48,0,0,1-1.79,4C140.51,107.89,129.53,110,119.4,110ZM96.61,95.57c8.45,2.61,30.73,6.82,42.54-1.55V32.9c-19,8-45-.15-46.25-.57a6.67,6.67,0,0,1-.74-.29C47.3,10.13,30.83,21.34,27,25c.09,5.27.34,19.78.48,34.26.12,11.85.17,20.11.12,25.92C38.78,80.09,59.82,77.69,96.61,95.57Z" />
              <path d="M79.19,77.5a5.49,5.49,0,0,1-3.85-1.57l-10.19-10a5.5,5.5,0,1,1,7.7-7.86L79,64.15,96,46.22a5.5,5.5,0,1,1,8,7.56l-20.81,22a5.54,5.54,0,0,1-3.9,1.72Z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">Objectives</div>
      </button>
      <button
        className={`nav-button ${
          currentSection === "how-to-play" ? "active" : ""
        }`}
        onClick={() => navigateTo("how-to-play")}
        aria-label="Navigate to How to play Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 26 27">
            <g>
              <path d="M14.1,7.9c-0.6-1-1.3-1.4-2.5-1.3c-1.6,0.1-3.2,0-4.9,0C6,6.6,5.6,6.2,5.6,5.5c0-1.3,0-2.7,0-4 c0-0.7,0.4-1.1,1.1-1.1c2.9,0,5.7,0,8.6,0c0.3,0,0.6,0.2,0.8,0.4c0.9,0.8,1.7,1.7,2.7,2.7c0.5-0.5,1-1.1,1.4-1.5 c0.7-0.7,1.1-0.7,1.8,0c0.9,0.8,1.7,1.7,2.6,2.6c0.7,0.7,0.7,1.3,0,2c-0.4,0.5-0.9,0.9-1.5,1.4c0.9,0.8,1.7,1.6,2.4,2.4 c0.2,0.2,0.4,0.6,0.4,1c0,2.8,0,5.6,0,8.4c0,0.8-0.4,1.1-1.1,1.1c-1.3,0-2.7,0-4,0c-0.7,0-1.2-0.4-1.1-1.2c0-1.7,0-3.4,0-5 c0-0.9-0.3-1.6-1.1-2.1c-0.2,0.2-0.4,0.4-0.6,0.6C13.7,17.5,9.5,21.7,5.2,26c-1,1-1.5,1-2.4,0c-0.7-0.7-1.4-1.4-2.1-2.1 c-0.9-0.9-0.9-1.3,0-2.2c4.3-4.4,8.7-8.8,13-13.2C13.8,8.2,13.9,8.1,14.1,7.9z M24,18.6c0-2.2,0-4.3,0-6.4c0-0.3-0.2-0.6-0.4-0.9 c-2.7-2.7-5.3-5.4-8-8c-0.3-0.3-0.7-0.6-1.1-0.6c-2.2-0.1-4.4,0-6.6,0c0,0.7,0,1.3,0,1.9c1.8,0,3.6,0,5.3,0c0.6,0,1,0.1,1.4,0.5 c2.2,2.3,4.5,4.5,6.8,6.8c0.3,0.3,0.5,0.8,0.5,1.3c0.1,1.8,0,3.6,0,5.4C22.6,18.6,23.2,18.6,24,18.6z M15.8,9.6 c-4.3,4.3-8.7,8.7-13,13c0.3,0.4,0.8,0.8,1,1.1c4.4-4.4,8.7-8.7,13-13C16.6,10.4,16.2,10,15.8,9.6z M20.4,5 c0.4,0.4,0.8,0.9,1.2,1.3c0.3-0.3,0.5-0.6,0.7-0.7c-0.5-0.5-0.8-0.9-1.2-1.3C20.9,4.5,20.6,4.7,20.4,5z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">How to play</div>
      </button>
      <button
        className={`nav-button ${
          currentSection === "for-teachers" ? "active" : ""
        }`}
        onClick={() => navigateTo("for-teachers")}
        aria-label="Navigate to For teachers Section"
        tabIndex={menuOpen ? null : -1}
      >
        <div className="nav-icon">
          <svg viewBox="0 0 28 26">
            <g>
              <path d="M3.2,3.5c0-0.6,0-1.1,0-1.6c0-0.9,0.5-1.4,1.4-1.4c2,0,4.1-0.1,6.1,0c0.9,0,1.8,0.4,2.7,0.6 c0.3,0.1,0.7,0.1,1,0.1c1-0.2,2-0.6,3-0.6c1.9-0.1,3.8,0,5.7,0c1.3,0,1.5,0.3,1.5,1.5c0,0.4,0,0.9,0,1.5c0.7,0,1.4,0,2,0 c1,0,1.3,0.4,1.3,1.3c0,5.4,0,10.9,0,16.3c0,0.9-0.4,1.3-1.4,1.3c-2.2,0-4.5-0.1-6.7,0C17.9,22.6,16.1,23,15,25 c-0.5,0.9-1.6,0.9-2.2,0.1c-1.3-1.9-3.2-2.6-5.4-2.6c-2,0.1-4,0-6,0C0.3,22.4,0,22.1,0,21c0-5.4,0-10.7,0-16.1 c0-1.1,0.3-1.4,1.5-1.4C2,3.5,2.5,3.5,3.2,3.5z M22.5,2.7c-2,0-3.8-0.2-5.7,0C15.3,2.9,15,3,15,4.7c0.1,2.8,0,5.7,0,8.5 c0,1.5,0.1,3,0.2,4.6c2.4-1,4.9-0.5,7.3-0.7C22.5,12.3,22.5,7.5,22.5,2.7z M5.5,17c2.5,0.2,4.9,0.3,7.3,0.5c0-0.1,0-0.3,0-0.6 c0-4.2,0-8.4,0-12.6c0-0.8-0.3-1.3-1-1.4c-2.1-0.1-4.1,0-6.3,0C5.5,7.5,5.5,12.3,5.5,17z M2.3,20.2c1.6,0,3,0,4.4,0 c0.8,0,1.7,0,2.5,0.1c1.8,0.2,3.4,1,4.7,2.2c0.5-0.4,1-0.8,1.5-1.1c1.6-0.9,3.4-1.3,5.2-1.3c1.7,0,3.3,0,5,0c0-4.9,0-9.6,0-14.4 c-0.4,0-0.7,0-1.1,0c0,0.4,0,0.7,0,1c0,3.7,0,7.5,0,11.2c0,1.2-0.2,1.4-1.5,1.4c-1.7,0-3.5,0-5.2,0c-1.2,0-2.2,0.4-2.9,1.5 c-0.5,0.9-1.7,0.9-2.2,0c-0.7-1.2-1.8-1.5-3-1.6c-1.7,0-3.3,0-5,0c-1.4,0-1.7-0.3-1.7-1.6c0-3.7,0-7.3,0-11c0-0.3,0-0.6,0-1 c-0.4,0-0.6,0-0.9,0C2.3,10.6,2.3,15.3,2.3,20.2z" />
            </g>
          </svg>
        </div>
        <div className="nav-text">For teachers</div>
      </button>
    </nav>
  );

  return nav;
};

export default StickyNav;
