import React from "react";
import BaseModule from "./BaseModule";
import "./Overview.scss";
import bank from "./images/bank.png";
import icon1 from "./images/overview_icon_1.png";
import icon2 from "./images/overview_icon_2.png";
import icon3 from "./images/overview_icon_3.png";
import icon4 from "./images/overview_icon_4.png";

class Overview extends BaseModule {
  render() {
    return (
      <div id="overview" className="overview-mod">
        <div id="overview-pin">
          <h2 id="overview-focus" tabIndex="-1" className="overview-header">
            game overview<span className="grapefruit">.</span>
          </h2>
          <div id="overview-move" className="overview-text static">
            <h2 className="overview-quote">
              “making financial concepts fun and hands-on is key to knowledge
              taking root and{" "}
              <span className="grapefruit">making a real difference</span> to
              the next generation.”
            </h2>

            <div className="overview-citation">
              <a
                href="https://www.ally.com/do-it-right/trends/how-ally-interns-created-fintropolis-a-new-minecraft-world?CP=LP2108022"
                onClick={this.openExternalLink}
                data-trackcategory="outbound link"
                data-trackaction="link click"
                data-tracklabel="keishon smith ally technology team and 2019 moguls in the making intern"
              >
                DeMari Tyner
                <br />
                Multicultural Marketing and 2019 Moguls in the Making intern
              </a>
            </div>
          </div>
          <div id="animate-overview-cards" className={`overview-cards`}>
            <div className="mobile-background"></div>
            <div id="card1" className="overview-card card1 static">
              <div className="overview-card__icon">
                <img src={icon1} alt=""></img>
              </div>
              <div className="overview-card__text">
                <h3 className="overview-card__header">career choices.</h3>
                <div className="overview-card__body">
                  Learn about jobs/careers and what kind of training is required
                  to start them.
                </div>
              </div>
            </div>
            <div id="card4" className="overview-card card4 static">
              <div className="overview-card__icon">
                <img src={icon4} alt=""></img>
              </div>
              <div className="overview-card__text">
                <h3 className="overview-card__header">
                  cultivating confidence.
                </h3>
                <div className="overview-card__body">
                  Getting to make some of life’s biggest decisions helps you get
                  ready for the real world.
                </div>
              </div>
            </div>
            <div id="card3" className="overview-card card3 static">
              <div className="overview-card__icon">
                <img src={icon3} alt=""></img>
              </div>
              <div className="overview-card__text">
                <h3 className="overview-card__header">life lessons.</h3>
                <div className="overview-card__body">
                  Play and you’ll discover how earlier financial decisions set
                  you up for the future, like which house you can buy.
                </div>
              </div>
            </div>

            <div id="card2" className="overview-card card2 static">
              <div className="overview-card__icon">
                <img src={icon2} alt=""></img>
              </div>
              <div className="overview-card__text">
                <h3 className="overview-card__header">money matters.</h3>
                <div className="overview-card__body">
                  Understand taxes, cash flow and budgeting, credit and debt,
                  and even investing as you play.
                </div>
              </div>
            </div>
          </div>
          <div id="animate-bank" className="bank bank-move static">
            <img className="bank-image" src={bank} alt=""></img>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
