import './ForTeachers.scss';
import background from './images/for_teachers_background.jpg';
import BaseModule from './BaseModule';

class ForTeachers extends BaseModule {
  render(){
    return (
      <div id="for-teachers" className="for-teachers-mod">
        <div className="for-teachers-upper">
          <img src={background} alt=""></img>
          <div className="for-teachers-upper__content">
            <div className="for-teachers-text">
              <h2 id='for-teachers-focus' tabIndex='-1' className="for-teachers-header">fintropolis for<br />the <span className="grapefruit">classroom.</span></h2>
              <div className="for-teachers-body">Teachers, follow these steps to set up Fintropolis. If you already have the Minecraft: Education Edition, start prepping for class by downloading the Fintropolis world and lesson plans.</div>
            </div>
            <div className="for-teachers-cards">
              <div className="for-teachers-card">
                <div className="for-teachers-card__icon">1</div>
                <div className="for-teachers-card__text"><a href="https://education.minecraft.net/get-started/" onClick={this.openExternalLink} data-trackcategory="outbound link" data-trackaction="link click" data-tracklabel="download minecraft education edition">Download Minecraft: Education Edition</a></div>
              </div>
              <div className="for-teachers-card">
                <div className="for-teachers-card__icon">2</div>
                <div className="for-teachers-card__text"><a href="https://education.minecraft.net/en-us/lessons/fintropolis" onClick={this.openExternalLink} data-trackcategory="outbound link" data-trackaction="link click" data-tracklabel="download the fintropolis world and lesson plans">Download the Fintropolis world and lesson plans</a></div>
              </div>
              <div className="for-teachers-card">
                <div className="for-teachers-card__icon">3</div>
                <div className="for-teachers-card__text">Open Minecraft: Education Edition and log in with your school email address</div>
              </div>
              <div className="for-teachers-card">
                <div className="for-teachers-card__icon">4</div>
                <div className="for-teachers-card__text">Upload the Fintropolis world into Minecraft to use in your lesson</div>
              </div>
              <div className="for-teachers-card">
                <div className="for-teachers-card__icon">5</div>
                <div className="for-teachers-card__text">Open the Fintropolis world, grab your lesson plan and play</div>
              </div>
              <div className="for-teachers-download"><a href="https://education.minecraft.net/en-us/lessons/fintropolis" onClick={this.openExternalLink} data-trackcategory="outbound link" data-trackaction="link click" data-tracklabel="download lesson plans">Download lesson plans</a></div>
            </div>
          </div>
        </div>
        <div className="for-teachers-lower">
          <p className="for-teachers-lower__text">Minecraft: Education Edition is now also available for camps, clubs, nonprofits and other organizations. <a href="https://education.minecraft.net/get-started/camps-and-clubs" onClick={this.openExternalLink} data-trackcategory="outbound link" data-trackaction="link click" data-tracklabel="learn more about its availability">Learn more about its availability.</a></p>
        </div>
      </div>
    )
  }
}

export default ForTeachers;