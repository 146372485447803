import './HowToPlay.scss';
import background from './images/how_to_play_background.png';
import icon1 from './images/how_to_play_icon_1.png'
import icon2 from './images/how_to_play_icon_2.png'
import icon3 from './images/how_to_play_icon_3.png'
import icon4 from './images/how_to_play_icon_4.png'

const HowToPlay = ({forceMobileMenu}) => {
  return (
    <div id="how-to-play" className="how-to-play-mod">
      <div className="how-to-play-image">
        <img src={background} alt=""></img>
        <div className={`how-to-play__upper ${forceMobileMenu ? 'forceMobileMenu' : ''}`}>
          <div className="how-to-play-text">
            <h2 id='how-to-play-focus' tabIndex='-1' className="how-to-play-header">getting started <span className='nowrap'>with <span className="grapefruit">fintropolis.</span></span></h2>
            <div className="how-to-play-body">Follow these steps to set up Fintropolis. If you already have the Minecraft Bedrock Platform downloaded, you can search ‘Fintropolis’ from within the Marketplace.</div>
          </div>
        </div>
      </div>
      <div className="how-to-play__lower">
        <div className="how-to-play-cards">
          <div className="how-to-play-card">
            <div className="how-to-play-card__icon">
              <img src={icon1} alt=""></img>
            </div>
            <div className="how-to-play-card__text">Purchase and download Minecraft Bedrock Edition on your favorite gaming device.</div>
          </div>    
          <div className="how-to-play-card">
            <div className="mobile-border"></div>
            <div className="how-to-play-card__icon">
              <img src={icon2} alt=""></img>
            </div>
            <div className="how-to-play-card__text">Open up the Marketplace within Minecraft on your device.</div>
          </div>
          <div className="how-to-play-card">
            <div className="mobile-border"></div>
            <div className="how-to-play-card__icon">
              <img src={icon3} alt=""></img>
            </div>
            <div className="how-to-play-card__text">Search 'Fintropolis' by Blockworks.</div>
          </div>
          <div className="how-to-play-card">
            <div className="mobile-border"></div>
            <div className="how-to-play-card__icon">
              <img src={icon4} alt=""></img>
            </div>
            <div className="how-to-play-card__text">Download and start playing.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToPlay;