import React from 'react'

import './Welcome.scss'

const Welcome = () => {
  return (
    <div id="welcome-container" className="welcome-mod">
      <div id="welcome" className="welcome-text">
        <h1 id="welcome-focus" className="welcome-header" tabIndex="-1" aria-level="1">welcome to <span className="grapefruit">fintropolis.</span></h1>
        <div className="welcome-body">Explore the world of Fintropolis and learn financial lessons along the way. A learning experience created by Blockworks in collaboration with Ally, and available for download in Minecraft.</div>
      </div>
    </div>
  )
}

export default Welcome;