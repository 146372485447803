
import React, { useState, useRef } from 'react'

import playIcon from './images/welcome-icon-play.png'
import videoThumbnail from './images/welcome-video-thumbnail.jpg'

import './HeroVideo.scss'

const HeroVideo = () => {
  const [showPlayButton, setShowPlayButton] = useState(true)
  const [showThumbnail, setShowThumbnail] = useState(true)

  const videoRef = useRef()

  const playVideo = () => {
    setShowThumbnail(false)
    setShowPlayButton(false)

    videoRef.current.play()
    videoRef.current.controls = true

  }
  const playStart = () => {
    let video = document.getElementById('intro-video')
    let scrollTop = window.pageYOffset || video.scrollTop;
    
    window.scrollTo({
      top: video.getBoundingClientRect().top + scrollTop,
      behavior: 'smooth'
    })
  }

  const endVideo = () => {
    setShowThumbnail(true)
    setShowPlayButton(true)
    
    videoRef.current.controls = false
  }

  return (
    <div id="HeroVideo">
      <div className="welcome-video">
        <video
          id="intro-video"
          ref={videoRef}
          src="/videos/fintropolis_trailer.mp4"
          poster={videoThumbnail}
          playsInline
          onEnded={endVideo}
          onPlay={playStart}
          title="Fintropolis Video Trailer"
        />
        <div className={`video-thumbnail ${showThumbnail ? "show" : ""}`}>
          <img src={videoThumbnail} alt="Video of Fintropolis game" />
        </div>
        <button
          className={`play-button ${showPlayButton ? "show" : ""}`}
          // role='button'
          aria-label="Play video"
          // tabIndex={0}
          onClick={playVideo}
          // onKeyDown={e => (e.key === 'Enter' ? playVideo() : null)}
        >
          <img src={playIcon} alt="Play video" />
        </button>
      </div>
    </div>
  );
}
export default HeroVideo;