import React, { useRef } from "react";
import Slider from "react-slick";
import "./Objectives.scss";
import ObjectivesCard from "./ObjectivesCard";
import icon1 from "./images/objectives_icon_1.png";
import icon2 from "./images/objectives_icon_2.png";
import icon3 from "./images/objectives_icon_3.png";
import icon4 from "./images/objectives_icon_4.png";
import icon5 from "./images/objectives_icon_5.png";
import icon6 from "./images/objectives_icon_6.png";

const CARDS = [
  {
    img: icon1,
    heading: "career & job opportunities.",
    body: "Visit a career fair and find out the skills and experience needed for different jobs.",
  },
  {
    img: icon2,
    heading: "tax time!",
    body: "Meet the mayor at city hall where you’ll learn how taxes work.",
  },
  {
    img: icon3,
    heading: "cash flow & budgeting.",
    body: "Open an account at the bank and find out about the value of budgeting.",
  },
  {
    img: icon4,
    heading: "credit & debt.",
    body: "Get a mortgage and learn about taking on good and bad debt.",
  },
  {
    img: icon5,
    heading: "investing.",
    body: "Head to the stock exchange and learn about investment growth and risk by developing a stock portfolio.",
  },
  {
    img: icon6,
    heading: "identity protection.",
    body: "Learn what it means to have your identity stolen, what to do about it, and ways to avoid it.",
  },
];

const Objectives = ({ forceMobileMenu }) => {
  const focusRefs = useRef(CARDS.map(() => React.createRef()));

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    afterChange: (index) => {
      focusRefs.current[index].current.focus();
      // console.log(focusRefs.current[index].current);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          customPaging: (i) => (
            <button aria-label={`${i + 1} of 3`}>{i + 1}</button>
          ),
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          customPaging: (i) => (
            <button aria-label={`${i + 1} of 6`}>{i + 1}</button>
          ),
        },
      },
    ],
  };

  return (
    <div id="objectives" className="objectives-mod">
      <div className="objectives-text">
        <h2 id="objectives-focus" tabIndex="-1" className="objectives-header">
          game objectives<span className="grapefruit">.</span>
        </h2>
      </div>
      {forceMobileMenu ? (
        <Slider {...settings}>
          {CARDS.map((card, index) => (
            <ObjectivesCard key={index} reference={focusRefs.current[index]} {...card} />
          ))}
        </Slider>
      ) : (
        <div className="objectives-cards">
          {CARDS.map((card,index) => (
            <ObjectivesCard key={index} {...card} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Objectives;
