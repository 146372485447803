import './ObjectivesCard.scss';
import PropTypes from 'prop-types';

const ObjectivesCard = (props) => {
  return (
    <div className="objectives-card">
      <div className="objectives-card__icon">
        <img src={props.img} alt={props.heading}></img>
      </div>
      <div className="objectives-card__text">
        <h3
          className="objectives-card__header"
          tabIndex={props.tabbable ? 0 : -1}
          ref={props.reference}
        >
          {props.heading}
        </h3>
        <p className="objectives-card__body">{props.body}</p>
      </div>
    </div>
  );
}

ObjectivesCard.propTypes = {
  img: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  class: PropTypes.string,
  tabbable:PropTypes.bool
};

export default ObjectivesCard;