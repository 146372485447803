import React from "react";
import PropTypes from "prop-types";

import "./Header.scss";
import siteLogo from "./images/fintropolis_logo.png";
import allyLogo from "./images/ally_logo.svg";
import StickyNav from "./StickyNav";
import FocusTrap from "focus-trap-react"; // ESM

import { trackClick } from "./BaseModule";

const HamburgerButton = ({ forceMobileMenu, menuOpen, hidden, ...rest }) => (
  <button
    id="hamburger-toggle"
    className={`hamburger ${forceMobileMenu ? "forceMobileMenu" : ""}`}
    tabIndex="0"
    aria-expanded={menuOpen ? true : false}
    aria-label={`${menuOpen ? 'Close' : 'Open'} Navigation Menu`}
    {...rest}
    style={{ display: hidden ? "none" : "" }}
  >
    <svg width="40" height="23">
      <rect width="40" height="5" fill="#fff"></rect>
      <rect y="9" width="40" height="5" fill="#fff"></rect>
      <rect y="18" width="40" height="5" fill="#fff"></rect>
    </svg>
  </button>
);

const Header = ({
  forceMobileMenu,
  currentSection,
  scrollToSection,
  menuOpen,
  setMenuOpen,
}) => {
  const toggleMenu = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  return (
    <header className="header" role="banner">
      <div className="logo" aria-hidden={menuOpen ? true : false}>
        <img
          className="fintropolis-logo"
          src={siteLogo}
          alt="Fintropolis logo"
        ></img>
        <a
          href="https://www.ally.com/"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackClick("header", "logo", "ally bank")}
        >
          <img
            className="ally-logo"
            src={allyLogo}
            alt="Ally Logo, go to ally.com"
          ></img>
        </a>
      </div>

      <FocusTrap
        active={menuOpen}
        focusTrapOptions={{
          onDeactivate: () => {
            setMenuOpen(false);
          },
          clickOutsideDeactivates: true,
        }}
      >
        <div>
          <HamburgerButton
            forceMobileMenu={forceMobileMenu}
            menuOpen={menuOpen}
            onClick={() => toggleMenu()}
          />

          <StickyNav
            menuOpen={menuOpen}
            forceMobileMenu={forceMobileMenu}
            currentSection={currentSection}
            scrollToSection={scrollToSection}
          />
        </div>
      </FocusTrap>

      <div className="overlay" data-visible={menuOpen}></div>
    </header>
  );
};

Header.propTypes = {
  menuOpen: PropTypes.bool,
  animation: PropTypes.bool,
  forceMobileMenu: PropTypes.bool,
  currentSection: PropTypes.string,
};

export default Header;
