import './Footer.scss';
import PropTypes from 'prop-types';
import facebook from './images/facebook_logo.png';
import twitter from './images/twitter_logo.png';
import youtube from './images/youtube_logo.png';
import instagram from './images/instagram_logo.png';
import linkedin from './images/linkedin_logo.png';
import BaseModule from './BaseModule';

class Footer extends BaseModule {
  render(){
    return (
      <footer className="footer" role='contentinfo' aria-hidden={this.props.menuOpen?true:false}>
        <div className="footer-icons">
          <div className="footer-icon">
            <a href="https://www.facebook.com/ally" aria-hidden={this.props.menuOpen?true:false} target="_blank" rel="noreferrer" onClick={()=>this.trackClick('footer','social','facebook')}><img src={facebook} alt="Facebook logo"></img></a>
          </div>
          <div className="footer-icon">
            <a href="https://twitter.com/Ally" aria-hidden={this.props.menuOpen?true:false} target="_blank" rel="noreferrer" onClick={()=>this.trackClick('footer','social','twitter')}><img src={twitter} alt="Twitter logo"></img></a>
          </div>
          <div className="footer-icon">
            <a href="https://www.youtube.com/user/ally" aria-hidden={this.props.menuOpen?true:false} target="_blank" rel="noreferrer" onClick={()=>this.trackClick('footer','social','youtube')}><img src={youtube} alt="YouTube logo"></img></a>
          </div>
          <div className="footer-icon">
            <a href="https://www.instagram.com/ally/" aria-hidden={this.props.menuOpen?true:false} target="_blank" rel="noreferrer" onClick={()=>this.trackClick('footer','social','instagram')}><img src={instagram} alt="Instagram logo"></img></a>
          </div>
          <div className="footer-icon">
            <a href="https://www.linkedin.com/company/ally/" aria-hidden={this.props.menuOpen?true:false} target="_blank" rel="noreferrer" onClick={()=>this.trackClick('footer','social','linkedin')}><img src={linkedin} alt="Linkedin logo"></img></a>
          </div>
        </div>
        <hr className="footer-rule"/>
        <div className="footer-bottom">
          <div className="footer-bottom-links">
            <div className="footer-link" onClick={()=>this.trackClick('footer','link click','security')}><a href="https://www.ally.com/security/" target="_blank" rel="noreferrer" aria-hidden={this.props.menuOpen?true:false}>Security</a></div>
            <div className="footer-link" onClick={()=>this.trackClick('footer','link click','privacy')}><a href="https://www.ally.com/privacy/" target="_blank" rel="noreferrer" aria-hidden={this.props.menuOpen?true:false}>Privacy</a></div>
            <div className="footer-link" onClick={()=>this.trackClick('footer','link click','legal')}><a href="https://www.ally.com/legal/" target="_blank" rel="noreferrer" aria-hidden={this.props.menuOpen?true:false}>Legal</a></div>
          </div>
          <div className="footer-legal">
            <div className="footer-legal">©2021 Ally Financial Inc. NOT AN OFFICIAL MINECRAFT PRODUCT. NOT APPROVED BY OR ASSOCIATED WITH MOJANG.</div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  menuOpen: PropTypes.bool
};


export default Footer;