import './About.scss';
import aboutImage from './images/about_background.jpg';
import BaseModule from './BaseModule';

class About extends BaseModule {
  render(){
    return (
      <div id='about' className='about-mod'>
      <div className='about-container'>
        <div className='about-box'>
          <h2 id='about-focus' tabIndex='-1' className='about-header'>about the <span className='grapefruit'>world.</span></h2>
          <p className='about-body'>
          Fintropolis is a world for Minecraft, that uses real-world scenarios to introduce and familiarize students and users to financial concepts. Other characters guide you through the activities, so no background knowledge is needed. Explore the city, earn gold to purchase homes, and learn the value of decisions, like spending vs saving.
          </p>
        </div>
        <div className='about-image-mobile'>
          <img src={aboutImage} alt='' />
        </div>
      </div>
      </div>
    )
  }
}

export default About;